<template>
  <div id="app">
    <div class="jbmb">
      <div class="banner">
        <h1>方案名称2019-11-13监测日报</h1>
        <p><span>报告于2019-11-13 09:00由舆情管理系统生成</span></p>
      </div>
      <div class="cont">
        <h2>一、概述</h2>
        <p>
          今日总体舆论信息量处于正常范围内。今日新增负面信息为中国网财经发布的《正荣地产资金链透视：年内8次发美元债融资总负债近1300亿元拿地放缓》，该文章的逐渐开始扩散，现阶段引起关注的媒体较少，大部分都是原发的分发账号，负面舆情态势还在可控范围内。</p>
        <p>
          正面舆论信息《“荣光计划”|南昌正荣走进佛塔小学，关爱留守儿童》、《公益助学“荣光计划”走进南昌佛塔小学关爱留守儿童》、《中建二局二公司南昌正荣开展劳动竞赛启动会》等较为突出，传播受众范围较宽泛，得到大部分媒体、自媒体的响应、转发。</p>
        <h2 class="mar-t-20">二、舆情态势纵览</h2>
        <h3 class="h3">时间段：2019年10月30日16点—2019年10月31日16点</h3>
        <h3 class="h3">（一）监测关键词</h3>
        <table class="jcgjc mar-t-10">
          <tr>
            <td>正荣监测关键词</td>
            <td>正荣、正荣集团、正荣地产、正荣产业、正荣资本、正荣物业、正荣质量</td>
          </tr>
          <tr>
            <td>公司类监测关键词</td>
            <td>正荣、zr、欧宗荣、王本龙、和山正院、中储、栋境、九和府、九里晴川、悦棠府</td>
          </tr>
          <tr>
            <td>人物类监测关键词</td>
            <td>欧宗荣、欧国强、欧国伟、欧国耀、欧宗洪、欧宗金等等</td>
          </tr>
          <tr>
            <td>项目类关键词</td>
            <td>环渤海区域、济南、苏沪区域、南京、合肥、福州、南昌、武汉、长沙、西安、郑州、环渤海区域、济南、苏沪区域、南京、合肥、福州、南昌、厦门区域全部项目</td>
          </tr>
        </table>
        <h3 class="h3">（二）日信息量</h3>
        <p> 宜华总信息量（633条）：公司（563条）、人物（12条）、项目（58条）</p>
        <h3 class="h3">（三）信息声量走势</h3>
        <p>整个监测周期内，11月18日0点信息声量达7条,11月18日1点信息声量达6条，是舆论的高峰。11月17日14点为舆论声量低谷，信息声量为0条。</p>
        <!-- 信息声量走势 -->
        <div class="box-card h-400 mar-t-20 xxslzs">
          <h3 class="mod-title">信息声量走势</h3>
          <div class="echarts_empty hide">
            <div class="img"></div>
          </div>
          <div id="voicetrend" class="w-100 h-380">
          </div>
        </div>
        <!--@end 信息声量走势 -->
        <h3 class="h3">（四）信息属性概况</h3>
        <p>负面信息占比为31%，正面及中性信息占比为69%。</p>
        <!-- 敏感信息占比 -->
        <div class="box-card h-400 mar-t-20 xxslzs">
          <h3 class="mod-title">敏感信息占比</h3>
          <div class="echarts_empty hide">
            <div class="img"></div>
          </div>
          <div id="sensitive" class="w-100 h-380">
          </div>
        </div>
        <!--@end 敏感信息占比 -->
        <h3 class="h3">（五）主要正面舆情焦点</h3>
        <table class="zyjd mar-t-20 w-100">
          <tr>
            <th>《楼面价4010元/m！正荣4.16亿元竞得晋东超10万方一线临江商住地！》</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td><p class="summary">
              昨17:31左右，各大地产媒体、自媒体在微信公众号上发布此正面报道，并引发天天快报、今日头条、百家号等等媒体相继发布此正面报道，对树立企业良好的正面形象以及打造正荣优质品牌起到了促进作用，扩散速率较快，转载扩散媒体量较多，正面宣传效果显著，受众范围广。</p>
            </td>
            <td>34</td>
            <td><span class="zm">正面</span></td>
          </tr>
          <tr>
            <td colspan="3">链接：http://kuaibao.qq.com/s/20191030A0JM9S00</td>
          </tr>
        </table>
        <h3 class="mar-t-10 h3">（六）主要负面舆情焦点</h3>
        <table class="zyjd mar-t-20 w-100">
          <tr>
            <th>《楼面价4010元/m！正荣4.16亿元竞得晋东超10万方一线临江商住地！》</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td><p class="summary">
              昨17:31左右，各大地产媒体、自媒体在微信公众号上发布此正面报道，并引发天天快报、今日头条、百家号等等媒体相继发布此正面报道，对树立企业良好的正面形象以及打造正荣优质品牌起到了促进作用，扩散速率较快，转载扩散媒体量较多，正面宣传效果显著，受众范围广。</p>
            </td>
            <td>34</td>
            <td><span class="fm">负面</span></td>
          </tr>
          <tr>
            <td colspan="3">链接：http://kuaibao.qq.com/s/20191030A0JM9S00</td>
          </tr>
        </table>
        <h3 class="h3">（七）内容传播分析（根据当天所设关键词反馈的全网信息词汇总结形成）</h3>
        <p>正面高频词汇：低调、美好、成功、打造、营造、确保</p>
        <p>负面高频词汇：违规、罚单、风险、困境、下滑、困难</p>
        <!-- 高频情感词汇 -->
        <div class="box-card h-400 mar-t-20 xxslzs">
          <h3 class="mod-title">高频情感词汇</h3>
          <div class="echarts_empty hide">
            <div class="img"></div>
          </div>
          <div id="words" class="w-100 h-380">
          </div>
        </div>
        <!--@end 高频情感词汇 -->
        <h2>三、行业竞品舆情态势</h2>
        <h3 class="h3">（一）旭辉</h3>
        <p>日信息总量：3783条</p>
        <div class="flex">
          <p>情感分布：</p>
          <div>
            <p>正面及中性信息占比92.41%</p>
            <p>负面信息占比7.59%</p>
          </div>
        </div>
        <p>热点信息（包含正负面）：</p>
        <table class="zyjd mar-t-20 w-100">
          <tr>
            <th>《楼面价4010元/m！正荣4.16亿元竞得晋东超10万方一线临江商住地！》</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td><p class="summary">
              昨17:31左右，各大地产媒体、自媒体在微信公众号上发布此正面报道，并引发天天快报、今日头条、百家号等等媒体相继发布此正面报道，对树立企业良好的正面形象以及打造正荣优质品牌起到了促进作用，扩散速率较快，转载扩散媒体量较多，正面宣传效果显著，受众范围广。</p>
            </td>
            <td>34</td>
            <td><span class="zm">正面</span></td>
          </tr>
          <tr>
            <td colspan="3">链接：http://kuaibao.qq.com/s/20191030A0JM9S00</td>
          </tr>
        </table>
        <table class="zyjd mar-t-20 w-100">
          <tr>
            <th>《楼面价4010元/m！正荣4.16亿元竞得晋东超10万方一线临江商住地！》</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td><p class="summary">
              昨17:31左右，各大地产媒体、自媒体在微信公众号上发布此正面报道，并引发天天快报、今日头条、百家号等等媒体相继发布此正面报道，对树立企业良好的正面形象以及打造正荣优质品牌起到了促进作用，扩散速率较快，转载扩散媒体量较多，正面宣传效果显著，受众范围广。</p>
            </td>
            <td>34</td>
            <td><span class="fm">负面</span></td>
          </tr>
          <tr>
            <td colspan="3">链接：http://kuaibao.qq.com/s/20191030A0JM9S00</td>
          </tr>
        </table>
        <h3 class="h3">（二）龙湖</h3>
        <p>日信息总量：3783条</p>
        <div class="flex">
          <p>情感分布：</p>
          <div>
            <p>正面及中性信息占比92.41%</p>
            <p>负面信息占比7.59%</p>
          </div>
        </div>
        <p>热点信息（包含正负面）：</p>
        <table class="zyjd mar-t-20 w-100">
          <tr>
            <th>龙湖光年首开热销！销售员：意料之中的人气爆棚</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td><p class="summary">
              众所周知，自龙湖光年入市以来，每一次举动皆刷新青岛人气记录，这次开盘更是如此。现场座无虚席，成双结对甚至举家前来选房。一双双焦急的眼睛，一颗颗企盼的心，大家迫不及待地等待着工作人员宣布选房的开始。对于这场没有硝烟的抢房大战掀起的火爆人气，正如现场销售人员所说：意料之中，早已习惯…</p>
            </td>
            <td>34</td>
            <td><span class="zm">正面</span></td>
          </tr>
          <tr>
            <td colspan="3">链接：http://kuaibao.qq.com/s/20191030A0JM9S00</td>
          </tr>
        </table>
        <table class="zyjd mar-t-20 w-100">
          <tr>
            <th>龙湖光年首开热销！销售员：意料之中的人气爆棚</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td><p class="summary">
              众所周知，自龙湖光年入市以来，每一次举动皆刷新青岛人气记录，这次开盘更是如此。现场座无虚席，成双结对甚至举家前来选房。一双双焦急的眼睛，一颗颗企盼的心，大家迫不及待地等待着工作人员宣布选房的开始。对于这场没有硝烟的抢房大战掀起的火爆人气，正如现场销售人员所说：意料之中，早已习惯…</p>
            </td>
            <td>34</td>
            <td><span class="fm">负面</span></td>
          </tr>
          <tr>
            <td colspan="3">链接：http://kuaibao.qq.com/s/20191030A0JM9S00</td>
          </tr>
        </table>
        <h3 class="h3">（三）融创</h3>
        <p>日信息总量：3783条</p>
        <div class="flex">
          <p>情感分布：</p>
          <div>
            <p>正面及中性信息占比92.41%</p>
            <p>负面信息占比7.59%</p>
          </div>
        </div>
        <p>热点信息（包含正负面）：</p>
        <table class="zyjd mar-t-20 w-100">
          <tr>
            <th>高价买了“融创”小区却变“金成”?</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td><p class="summary">
              李女士：当时买这个楼盘的时候，他们的销售告诉我们，这个就是融创金成未来海，我们所有买这个楼盘的业主，也是冲着融创这块牌子来买的。买完之后，我们才发现，物业也好，合同备案也好，都是金成的，跟融创没有任何关系。自己花大价钱买了南面的溪汀，却发现，溪汀和澜湾，不是一个小区…</p>
            </td>
            <td>34</td>
            <td><span class="zm">正面</span></td>
          </tr>
          <tr>
            <td colspan="3">链接：http://kuaibao.qq.com/s/20191030A0JM9S00</td>
          </tr>
        </table>
        <table class="zyjd mar-t-20 w-100">
          <tr>
            <th>高价买了“融创”小区却变“金成”</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td><p class="summary">
              李女士：当时买这个楼盘的时候，他们的销售告诉我们，这个就是融创金成未来海，我们所有买这个楼盘的业主，也是冲着融创这块牌子来买的。买完之后，我们才发现，物业也好，合同备案也好，都是金成的，跟融创没有任何关系。自己花大价钱买了南面的溪汀，却发现，溪汀和澜湾，不是一个小区…</p>
            </td>
            <td>34</td>
            <td><span class="fm">负面</span></td>
          </tr>
          <tr>
            <td colspan="3">链接：http://kuaibao.qq.com/s/20191030A0JM9S00</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import {globalGetEcharts} from "@/utils/helpers";
  const d3 = require("d3");
  export default {
    name: 'brief-daily-mix',
    data() {
      return {
        voicetrend: {
          "keys": ["2019-11-17 12:00:00", "2019-11-17 13:00:00", "2019-11-17 14:00:00", "2019-11-17 15:00:00", "2019-11-17 16:00:00", "2019-11-17 17:00:00", "2019-11-17 18:00:00", "2019-11-17 19:00:00", "2019-11-17 20:00:00", "2019-11-17 21:00:00", "2019-11-17 22:00:00", "2019-11-17 23:00:00", "2019-11-18 00:00:00", "2019-11-18 01:00:00", "2019-11-18 02:00:00", "2019-11-18 03:00:00", "2019-11-18 04:00:00", "2019-11-18 05:00:00", "2019-11-18 06:00:00", "2019-11-18 07:00:00", "2019-11-18 08:00:00"],
          "data": [3, 1, 0, 1, 1, 5, 1, 1, 3, 1, 2, 2, 7, 6, 1, 1, 2, 1, 2, 2, 2]
        },
        sensitive: {"negative": 31, "positive": 69},
        words: [
          {keyword: '公告', total: 20, emotion: 'positive'},
          {keyword: '财富', total: 30, emotion: 'positive'},
          {keyword: '跻身', total: 10, emotion: 'positive'},
          {keyword: '成功', total: 32, emotion: 'positive'},
          {keyword: '低调', total: 36, emotion: 'positive'},
          {keyword: '美好', total: 36, emotion: 'positive'},
          {keyword: '打造', total: 22, emotion: 'positive'},
          {keyword: '营造', total: 22, emotion: 'positive'},
          {keyword: '确保', total: 23, emotion: 'positive'},
          {keyword: '违规', total: 23, emotion: 'negative'},
          {keyword: '罚单', total: 23, emotion: 'negative'},
          {keyword: '风险', total: 23, emotion: 'negative'},
          {keyword: '困境', total: 23, emotion: 'negative'},
          {keyword: '下滑', total: 23, emotion: 'negative'},
          {keyword: '困难', total: 23, emotion: 'negative'},
          {keyword: '王健林', total: 23, emotion: 'negative'},
        ]
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.voic()
        this.sen()
        this.wordPopChart(this.words, 'words')
      })
    },
    methods: {
      voic() {
        let map = window.document.getElementById('voicetrend')
        var echarts = globalGetEcharts();
        var myChart = echarts.init(map);
        var voicetrend = this.voicetrend;
        var data = voicetrend
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            top: "5px",
            icon: "circle",
            itemGap: 5,
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            show: false
          },
          grid: {
            left: '20px',
            right: '40px',
            top: '20px',
            bottom: '0',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: []
          },
          yAxis: {
            axisLabel: {
              formatter: function (value) {
                return value;
              }
            }
          },
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          color: "#65A4FB",
          series: [{
            name: "数据总量",
            type: 'line',
            smooth: true,
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: function (params) {
                  return params.data.name
                }
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#65A4FB'
                }, {
                  offset: 1,
                  color: '#E4F0FF'
                }])
              }
            },
            data: []
          }]
        }
        option.xAxis.data = data.keys.map(function (item, index) {
          let temp = item.split("-")
          temp.shift()
          return temp.join("-")
        })
        option.series[0].data = data.data.map(function (v, index) {
          if (v === 7) {
            return {
              name: '',
              symbol: 'image:///static/img/dian_lan.svg',
              symbolSize: 15,
              value: v
            }
          } else if (v === 0) {
            return {
              name: '',
              symbol: 'image:///static/img/dian_lan.svg',
              symbolSize: 15,
              value: v
            }
          } else {
            return {
              name: '',
              value: v
            }
          }
        })
        myChart.setOption(option)
      },
      sen() {
        let map = window.document.getElementById('sensitive')
        var echarts = globalGetEcharts();
        var myChart = echarts.init(map);
        var sensitive = this.sensitive;
        var result = sensitive
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
          },
          legend: {
            orient: 'vertical',
            // x: 'left',
            data: ['敏感', '非敏感'],
            bottom: "0",
            left: "20px",
            itemWidth:10,
            itemHeight:10,
            itemGap:5,
            icon: "circle"
          },
          color: ['#FC5D73','#555DFE'],
          series: [
            {
              name: '情感分布',
              type: 'pie',
              center: ['50%', '45%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: [{name: "敏感", value: result.negative}, {name: "非敏感", value: result.positive}]
            }
          ]
        })
      },
      wordPopChart(data, id) {
        var maxr = data.length >= 1 ? data.sort(function (a, b) {
          return b.total - a.total
        })[0].total : 0
        function typeStr(obj) {
          return Object.prototype.toString.call(obj).toLowerCase();
        }
        function Bubble() {
          var tempObject = new Object();
          tempObject.setOption = function (option) {
            this.width = option.width;
            this.height = option.height;
            this.padding = option.padding;
            this.data = option.data;//数据url或对象,必填
            this.conEle = option.conEle;//svg容器(node或者选择器)，必填
          }
          tempObject.init = function () {
            var that = this;
            //1.设置颜色
            var color = d3.scale.category20c();
            //2.布局
            var bubble = d3.layout.pack()
            .sort(null)
            .size([that.width, that.height])
            .radius(function (d) {
              var val = d / maxr * 50
              return val < 20 ? 20 : val
            })
            .padding(that.padding);
//          //3.添加svg元素
            var svg = d3.select(that.conEle).append("svg")
            .attr("width", that.width)
            .attr("font-size", '12')
            .attr("height", that.height);
            //4.数据请求及图形绘制
            if (typeStr(that.data) == '[object string]') {
              d3.json(that.data, function (error, data) {
                if (error) throw error;
                //1.对数据进行处理
                data = dataHandle(data);
                render(svg, bubble, that, data);
              })
            } else {
              render(svg, bubble, that, dataHandle(that.data));
            }
          }
          return tempObject;
        }
        //Returns a flattened hierarchy containing all leaf nodes under the root.
        function classes(root) {
          var classes = [];                                                                                        //存储结果的数组
          /*
           * 自定义递归函数
           * 第二个参数指传入的json对象
           */
          function recurse(name, node) {
            if (node.children)                                                                                   //如果有孩子结点 （这里的children不是自带的，是json里面有的）
            {
              node.children.forEach(function (child) {                                                          //将孩子结点中的每条数据
                recurse(node.name, child);
              })
            } else {
              //如果自身是孩子结点的，将内容压入数组
              classes.push({name: node.name, value: node.size, props: node.props})
            }
            ;
          }
          recurse(null, root);
          return {children: classes};
        }
        function render(view, layout, context, data, cb) {
          var node = view.selectAll(".node")
          //绑定数据（配置结点）
          .data(layout.nodes(classes(data))
          .filter(function (d) {
            //数据过滤，满足条件返回自身（没孩子返回自身，有孩子不返回，这里目的是去除父节点）
            return !d.children;
          }))
          .enter().append("g")
          .attr("class", "node")
          .attr("transform", function (d) {
            //设定g移动
            return "translate(" + d.x + "," + d.y + ")";
          });
          var usingNodes = node.filter(function (d) {
            return d.props.using;
          });
          var time = +new Date();
          var duration = 1000;
          var strokeWidth = 0;
          node.append("circle")
          .attr("r", function (d) {
            //设置圆的半径
            return d.r;
          })
          .style("fill", function (d) {
            //气泡颜色
            return d.props.color;
          })
          .style("fill-opacity", 1);
          node.append("text")
          .attr("dy", ".3em")
          //设置文本对齐
          .style("text-anchor", "middle")
          .style("font-size", '12px')
          .style("fill", function (d) {
            //字体颜色
            return "#fff"
          })
          //根据半径的大小来截取对应长度字符串(很重要)
          .text(function (d) {
            return d.name.substring(0, d.r / 8);
          });
          function animate() {
            var nowTime = +new Date();
            if ((nowTime - duration) > time) {
              time = nowTime;
              strokeWidth = 0;
            }
            strokeWidth += 0.6;
            //strokeWidth >10?strokeWidth=10:strokeWidth += 1;
            usingNodes.select("circle")
            .style("stroke-width", strokeWidth + 'px')
            .style("stroke-opacity", '0.3')
            .style("stroke", function (d) {
              return d.props.color;
            });
//          requestAnimationFrame(animate);
          }
          animate();
        }
        //定义数据处理方法
        function dataHandle(data) {
          var result = {
            name: "flare",
            children: []
          }
          data.forEach(function (ele) {
            result.children.push({
              name: ele.name,
              size: ele.value,
              props: ele.props
            });
          });
          return result;
        }
        var chartData = [];
        _(data).forEach((item, i) => {
          chartData.push({
            name: item.keyword,
            value: item.total,
            props: {
              abnormal: false,
              color: item.emotion == "positive" ? "#66a5ff" : "#fa3851",
              using: false
            }
          })
        })
        var element = window.document.getElementById(id)
        if (!element) return false // 切换时直接break
        var option = {
          data: chartData,
          conEle: '#' + id,
          width: 780,
          height: 380,
          padding: 2
        }
        var bubble = new Bubble()
        bubble.setOption(option)
        bubble.init()
      }
    }
  }
</script>
<style scoped>
* {margin: 0;padding: 0;}
table {border-collapse: collapse;}
a {text-decoration: none;cursor: pointer;color: #000;}
li {list-style: none;}
.mar-t-10 {margin-top: 10px;}
.t-c {text-align: center;}
#app {background: #E9EEF3;}
.mar-t-20 {margin-top: 20px;}
.w-100 {width: 100%;overflow: hidden;}
.jbmb {margin: 0 auto;background: #fff;padding-bottom: 30px;width: 1380px;}
.jbmb .banner {text-align: center;line-height: 60px;height: 200px;background-image: url(../../../assets/images/common/banner_bg.png);background-repeat: no-repeat;background-size: 100% 100%;-moz-background-size: 100% 100%;color: #fff;margin-bottom: 30px;}
.jbmb .banner h1 {font-size: 30px;padding-top: 30px;line-height: 80px;}
.jbmb .banner span {padding: 0 30px;font-size: 22px;color: rgba(255, 255, 255, .79);}
.jbmb .cont {padding: 0 50px;}
.jbmb h2 {font-size: 24px;line-height: 60px;margin-top: 20px;}
.jbmb p, .jbmb .h3 {font-size: 20px;line-height: 40px;color: rgba(0, 0, 0, .8);}
.jbmb .h3 {margin-top: 20px;}
.box-card {box-shadow: 0px 3px 15px 0px rgba(194, 194, 194, 0.4);border-radius: 6px;padding: 20px 0;}
/* 监测关键词 */.jcgjc td {padding: 20px 30px;line-height: 25px;border-bottom: 1px solid #fff;border-right: 1px #fff solid;}
.jcgjc {background: #2C68B7;}
.jcgjc tr > td:nth-child(odd) {font-size: 20px;color: #fff;width: 170px;}
.jcgjc tr > td:nth-child(even) {font-size: 18px;background: #C9E0FC;}
.jcgjc tr:nth-child(even) > td:nth-child(even) {background: #E4EFFD;}
/* 信息声量走势 */.xxslzs {width: 66%;margin: 20px auto;}
.h-400 {height: 400px;}
/*图标空*/.echarts_empty {width: 100%;height: 380px;display: flex;justify-content: center;align-items: center;}
.echarts_empty .img {width: 94px;height: 120px;overflow: hidden;background: url(/static/img/echarts_empty.png) 0 0 no-repeat;}
/* 主要焦点 */.zyjd, .zyjd tr, .zyjd td, .zyjd th {border: 1px #BFBFBF solid;font-size: 18px;}
.zyjd td, .zyjd th {padding: 10px 20px;line-height: 28px;border: 1px #BFBFBF solid;}
.zyjd td .summary {width: 800px;line-height: 28px;font-size: 16px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient: vertical;-webkit-line-clamp: 4;}
.zyjd th:nth-child(1) {text-align: left;}
.zyjd tr:nth-child(2) td:nth-child(2) {text-align: center;}
.fm {color: #f00;}
.zm {color: #000dfe;}
.hide {display: none;}
.w-100 {width: 100%;}
.h-380 {height: 380px;}
.mod-title{padding-left: 15px;margin-left: 20px;}
</style>
